export default {
  "en": {
    "competences_controller": {
      "alle_stellen_rund_um": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jobs for ", _interpolate(_named("competence_keywords_join"))])}
    },
    "components": {
      "applicant_service": {
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person for international applicants"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services for international applicants"])},
        "unternehmenssprachen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate languages"])}
      },
      "apply_card": {
        "beantworten_sie_bitte_folg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please answer the following questions from ", _interpolate(_named("job_organisation_name")), ":"])},
        "bms_privacy_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The organization ", _interpolate(_named("organisation")), " uses an applicant management system to process your application. The data protection declarations for the storage and processing of your data can be found <a href=\"", _interpolate(_named("privacyPolicyUrl")), "\" target=\"_blank\" class=\"text-primary-500 hover:text-primary-700\" style=\"text-decoration: underline\">here</a>."])},
        "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following information is still incomplete:"])},
        "external_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IMPORTANT NOTE: The ", _interpolate(_named("company")), " uses an applicant management system. You will be directed there by clicking on the “Apply” button. A new window / tab opens for this purpose."])},
        "gender_options": {
          "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
          "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms/Mdm"])},
          "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr"])}
        },
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apply now to the ", _interpolate(_named("company")), " as a ", _interpolate(_named("job")), "."])},
        "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime, the employer asks you to provide anonymous feedback on kanaleo on how you became aware of the position."])},
        "living_in_germany_options": {
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I live in <b>another country</b>"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I currently live <b>in Germany</b> / my official place of residence is in Germany"])}
        },
        "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing address"])},
        "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You already applied for this position on ", _interpolate(_named("date")), "."])},
        "privacy_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find the <a class=\"text-primary-500 hover:text-primary-700\" href=\"https://www.empfehlungsbund.de/datenschutz#bewerbungsformular\" target='_blank'>Privacy Policy of Empfehlungsbund and its job communities here</a>. Your data will only be temporarily stored here and will be deleted after a successful transmission to the organization."])},
        "required_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents required by the company:"])},
        "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check whether you meet these basic requirements:"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
        "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application was sent successfully!"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "zu_kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Kanaleo"])}
      },
      "empfehlungscodes_field": {
        "active_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active referral codes:"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add referral code"])},
        "did_you_receive_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you a received"])},
        "did_you_receive_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an referral/recommendation code"])},
        "did_you_receive_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only first-class professionals receive a referral code. The entrepreneurs of the community portals involved communicate regularly\nwho deserves a recommendation. Trust the judgment of the experts. Convince yourself of the strict rules in the\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.empfehlungsbund.de/faq#Bewerber\" target=\"_blank\"> FAQs of the Empfehlungsbund </a>.\nUse the referral code for your professional success.\n"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add referral code"])},
        "recommended_by": {
          "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended candidate, by ", _interpolate(_named("company"))])},
          "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Interesting candidate, from ", _interpolate(_named("company"))])}
        },
        "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use as a reference: The company I'm applying to here is welcome to ask the organization that issued me the referral code about the reasons for the referral during my application."])}
      },
      "filter_sidebar": {
        "job_type": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All kinds"])},
          "interns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprentices / internships"])},
          "professionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialists & executives"])}
        },
        "jobs4i": {
          "low_german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I speak little or no German (A1-A2)"])},
          "target_audience_country": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you currently live?"])},
            "options": {
              "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/EEA"])},
              "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
              "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Countries/Third States"])}
            }
          },
          "target_audience_work_permit": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a work permit for Germany or the EU?"])},
            "options": {
              "germany_eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I have a work permit"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have a work permit yet and I will need one"])}
            }
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter for job offers"])}
        },
        "portal_types": {
          "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer science, software development, etc."])},
          "MINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrical engineering, mechanical engineering, etc."])},
          "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business administration, economics, law, etc."])},
          "SANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors, therapists, nurses, etc."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjects"])}
        },
        "query_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search terms ..."])},
        "remote_type": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid and 100%"])},
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% at home (Germany)"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without a home office"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid: On-site with a home office part"])}
        },
        "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "footer": {
        "datenschutz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
        "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
        "stellen_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Employers"])}
      },
      "job_card": {
        "more_than_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 30 days"])},
        "services_for_internationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services for Internationals:"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "translated_content_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The job description is available in these languages"])}
      },
      "job_description_content": {
        "translate_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate with"])},
        "translations_of_this_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translations of this job description:"])}
      },
      "navbar": {
        "anzeige_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place an ad"])}
      },
      "non_german_ip_interstitial": {
        "country_options": {
          "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/EEA/Schengen"])},
          "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
          "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other country"])}
        },
        "modal_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems you're not in Germany right now. Can you tell us where you are currently located?"])},
        "notice": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Jobs4International.de"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This positions might require a <strong>work permit</strong> or <strong>Place of residence</strong>, that does not match your answers. We recommend that you search for suitable positions on Jobs4Internationals.de, where these criteria aren't mandatory. On that site, companies are listed that offer support in obtaining a work permit for Germany and help with relocation.\n<br>\nYou can still try to apply to this position here. However, you will very likely receive an immedieate rejection if you don't meet the requirements.\n"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information about your application"])}
        },
        "work_permit_options": {
          "germany_eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I already possess a work permit for Germany or another EU country."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I don't yet have a work permit for Germany or any other EU country.`"])}
        }
      },
      "subscribe_newsletter_bar": {
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new job offers that match your search terms above. You can unsubscribe from this newsletter at any time.\nFurther information can be found in our <a href=\"/datenschutz\" target=\"_blank\"> data protection information </a>.\n"])},
        "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new jobs by email"])},
        "new_jobs_per_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new jobs by email"])},
        "subscribe_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
        "subscribed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have sent an email to ", _interpolate(_named("email")), ". Please click on the link contained therein to start the newsletter agent. If necessary, please also look in the Spam / Junk folder."])}
      }
    },
    "elements": {
      "breadcrumbs": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home office jobs"])}
      },
      "pagination": {
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show <span class = \"font-medium\"> ", _interpolate(_named("from")), " </span> to <span class = \"font-medium\"> ", _interpolate(_named("to")), " </span> from <span class = \"font-medium\"> ", _interpolate(_named("count")), " </span> jobs"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "upload_field": {
        "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is uploaded:"])},
        "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop here"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
        "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a document"])}
      }
    },
    "form": {
      "messages": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])},
        "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to upload at least one file"])},
        "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " must be specified"])}
      },
      "properties": {
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/Municipality"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment / Cover letter"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your country of residence from the list"])},
        "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
        "current_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you currently live?"])},
        "current_work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a work permit for Germany or the EU?"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application documents"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given Name"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired salutation"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name / family name"])},
        "livingInGermany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where is your current place of residence?"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
        "question_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application short question"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])}
      }
    },
    "job_title_generator": {
      "at_homeoffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" full remote/WFH"])},
      "at_homeoffice_with_countries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" full remote/WFH (residents of ", _interpolate(_named("countries")), ")"])},
      "name_list_connector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
      "name_list_last_word_connector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as well as "])}
    },
    "meta_description": {
      "jobs": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOB DESCRIPTION"])},
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is a job advert from ", _interpolate(_named("portal")), ". You can find the full link to the job posting here: <a href=\"", _interpolate(_named("url")), "\">", _interpolate(_named("url")), "</a>."])},
        "keywords": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Important keywords for this ad: ", _interpolate(_named("keywords"))])},
        "outro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is a job advertisement from ", _interpolate(_named("portal")), " (member of Empfehlungsbund / EBND). Clicking on Apply will take you to the original advert on ", _interpolate(_named("hostname")), "."])}
      }
    },
    "models": {
      "fallback_portal_search": {
        "result": {
          "home_office_jobs_gefunden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pagy_count")), " home office jobs ", _interpolate(_named("q")), " found"])},
          "keine_stellenangebote_gefu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vacancies found"])},
          "stellen_gefunden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pagy_count")), " jobs ", _interpolate(_named("q")), " found"])},
          "zu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["for \"", _interpolate(_named("qq")), "\""])},
          "zu2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["for ", _interpolate(_named("query_competence_name"))])}
        }
      },
      "job": {
        "requirements_info": {
          "arbeitserlaubnis_in_deutsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work permit in Germany or Schengen/EU required"])},
          "arbeitsort_muss_erreichbar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Workplace must be accessible: ", _interpolate(_named("location_list_to_sentence"))])},
          "einsatz_in_schichtarbeit_n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift work required"])},
          "wohnort_muss_in_diesen_lae": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your place of residence must be in one of these countries: ", _interpolate(_named("remote_countries_list"))])}
        }
      }
    },
    "pages": {
      "jobs": {
        "index": {
          "jobs4i": {
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Jobs4Internationals.de, the job portal for international specialists in and outside of Germany. Here you will find job offers that either do not require high language skills in German, or are from companies that have an open welcoming culture and support Visa sponsoring or Blue Card applications."])}
          }
        },
        "show": {
          "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
          "ddf_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor of the Database Forum"])},
          "member_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of Silicon Saxony e.V."])}
        }
      },
      "jobs_invisible": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This job offer is no longer available and you are looking at an archive."])},
        "more_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar job offers"])},
        "open_jobs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Open job offers from ", _interpolate(_named("company")), " and other companies: "])}
      }
    },
    "salutation": {
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms."])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr."])}
    }
  }
}